<template>
  <section class="history">
    <h1>
      Bestellungen
      <button @click="download" class="csv">CSV</button>
    </h1>
    <p>
      <Datepicker v-model="range" range :enableTimePicker="false" format="dd.MM.yyyy" :inline="false" locale="de" :autoApply="false" cancelText="" selectText="Auswählen" @update:modelValue="storeRange(); load()" :clearable="false"/>
    </p>
    <History />
  </section>
</template>

<script>
// @ is an alias to /srcG
import History from '@/components/History.vue'

import Datepicker from 'vue3-date-time-picker'

function arrayToCsv(data){
  // thank you, stack overflow https://stackoverflow.com/a/68146412/187273
  // (changed to string append instead of map/join for performance and flexibility, adding header easy)
  // consider transliterating this one to python in case there might be some regular consumption via api
  var order_columns = ["id","received","cancelled","minutes","completed","preorder","cutlery","notify","notified","scheduled","comment","container","location","relevo_id","vytal_id","percentage","name","code","wolt_order_id","wolt_order_number","lieferando_order_key","lieferando_public_reference","uber_order_id","uber_order_display_id"]
  var item_columns = ['code', 'name', 'price', 'tax']
  function rowToCsv(row) {
    var csvrow = ''
    var first = false
    for (var item of row) {
      if (first)
        first = false
      else
        csvrow += ','
      csvrow += '"' + String(item == null ? '' : item).replaceAll('"', '""') + '"'
    }
    return csvrow
  }
  var columns = order_columns.concat(item_columns).concat(['discounted'])
  var csv = rowToCsv(columns) + '\r\n'
  var column
  var row
  for (var order of data) {
    for (var item of order['items']) {
      // emit one row per item
      row = []
      for (column of order_columns)
        row.push(order[column])
      for (column of item_columns)
        row.push(item[column])
      row.push(Math.round(item['price'] * (1 - 0.01 * order['percentage'])))
      csv += rowToCsv(row) + '\r\n'
      
      for (var component of item.components) {
        // also emit one row per component
        row = []
        for (column of order_columns)
          row.push(order[column])
        for (column of item_columns)
          row.push(component[column])
        row.push(Math.round(component['price'] * (1 - 0.01 * order['percentage'])))
        csv += rowToCsv(row) + '\r\n'
      }
    }
  }
  return csv
}

export default {
  name: 'HistoryView',
  data() {
    return {
      range: JSON.parse(localStorage.getItem('history range')) || [
        (new Date()).toISOString(),
        (new Date()).toISOString()
      ]
    }
  },
  methods: {
    load() {
      window.socket.send(JSON.stringify(["history", this.range[0], this.range[1]]))
    },
    storeRange() {
      localStorage.setItem('history range', JSON.stringify(this.range))
    },
    download() {
      var csv = arrayToCsv(this.$store.state.history)
      //console.log(csv)
      var blob = new Blob([csv], { type: 'text/csv'});
      var url = URL.createObjectURL(blob);
      var pom = document.createElement('a');
      var filename = this.$route.params['venue'] + '-' + this.range[0] + '-' + this.range[1] + '.csv'
      pom.href = url;
      pom.setAttribute('download', filename);
      pom.click();
    }
  },
  components: {
    History,
    Datepicker
  },
  mounted() {
    this.load()
  }
}
</script>

<style lang="scss">
@import 'vue3-date-time-picker/src/Vue3DatePicker/style/main.scss';

.history {
  table {
    th {
      width: 40px;
      &:nth-child(5) {
        width: auto;
      }
    }
    td {
      &:nth-child(5) {
        width: auto;
      }
    }

    td.order_details {
      white-space: pre;
      text-align: left;
    }
    td.order_price{
      white-space: pre;
      text-align: right;
    }

  }

  button.csv {
    float: right;
  }
}

.dp__theme_light {
  --dp-background-color: #ffffff;
  --dp-text-color: #212121;
  --dp-hover-color: #8dd4b4;
  --dp-hover-text-color: #42b983;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #42b983;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #ddd;
  --dp-menu-border-color: #ddd;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
}

</style>

