<template>
  <section class="orders">
    <Orders />
  </section>
</template>

<script>
// @ is an alias to /src
import Orders from '@/components/Orders.vue'

export default {
  name: 'OrdersView',
  components: {
    Orders
  }
}
</script>

<style lang="scss">

.orders {
  
  table {
    th {
      width: 40px;
      &:nth-child(5) {
        width: auto;
      }
    }
    td {
      &:nth-child(5) {
        padding: 0;
        width: auto;
      }
    }

    table.order-items-header, table.order-items-body {
      table-layout: fixed;
      td, th {
        width: 80px;
        padding: 8px;
        overflow-wrap: anywhere;
      }
    }
    tr:nth-child(odd) {
      tr:nth-child(odd){
        background-color: #333;
        color: #fff;
        font-weight: bold;
      }
      tr:nth-child(even){
        background-color: #666;
        color: #fff;
        font-weight: bold;
      }
    }
    tr:nth-child(even) {
      tr:nth-child(odd){
        background-color: #999;
        color: #fff;
        font-weight: bold;
      }
      tr:nth-child(even){
        background-color: #aaa;
        color: #fff;
        font-weight: bold;
      }
    }
  }
}
</style>
