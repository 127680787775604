<template>
  <table v-if="history.length">
    <tr>
      <th>
        #
      </th>
      <th>
       Datum 
      </th>
      <th>
        Zeit
      </th>
      <th colspan=2>
        Positionen 
      </th>
      <th>
        Discount
      </th>
      <th>
        Total (-Dis.) 
      </th>
      <th>
        Total 
      </th>
      <th>
        Bewirtungsbeleg
      </th>
    </tr>
    <tr v-for="order in history" v-bind:key="order.id">
      <td>
        {{ order.id }}
      </td>
      <td>
        {{ orderdate(order.received) }}
      </td>
      <td>
        {{ formatTime(order.received, true) }}
      </td>
      <td class="order_details">
        {{ orderDetails(order.items,0) }}
      </td>
      <td class="order_price">
        {{ orderDetails(order.items,1) }}
      </td>
      <td>
        {{ order.percentage }}
      </td>
      <td>
        {{ order.total_pre / 100 }}
      </td>
      <td>
        {{ order.total / 100 }}
      </td>


      <td>
        <a :href="url(order)" target="_blank">PDF</a>
      </td>
    </tr>

    <tr>
    <th></th>
    <th></th>
    <th></th>
    <th colspan=4></th>
    <th>{{ grandtotal() /100 }}</th>
    <th></th>
    </tr>
  </table>
  <p v-else>
    Es gibt im angegebenen Zeitraum keine Bestellungen
  </p>
</template>

<script>

import dayjs from 'dayjs';


export default {
  name: 'Orders',
  props: {
    msg: String
  },
  methods: {
    // window.socket.send(JSON.stringify(["completed", order_id]))
    url (order) {
      return process.env.VUE_APP_API_URL + '/order/receipt/' + order.id + '/' + order.key
    },
    orderdate(received){
       return dayjs(received).format('DD.MM.YY') 
    },
    grandtotal() {
      let history =  this.history || []

      let sum = 0;
      for(let i = 0; i < history.length; i++) {
         sum += history[i]['total'];
      }
      return sum;
    },
    orderDetails(items,type = 0,prefix = "") {
      let details = "";

       for(let i=0;i<items.length;i++){
        if(type == 0) details += prefix + items[i]['name'] + ": ";
        if(type == 1){
        if("quantity" in items[i] && items[i]["quantity"] >1) details += items[i]["quantity"] + "x ";
        details += items[i]['price']/100 + " EUR";
        }
        details += "\n";
        if(items[i]['components']) details += this.orderDetails(items[i]['components'],type, "     - ");
      }

      return details;
    }

  },
  computed: {
    history() {
      if ( ! this.$store.state.history) return []
      var history = []
      for (let i = 0; i < this.$store.state.history.length; i++) {
        var order = this.$store.state.history[i]
        if (order.lieferando_order_key) continue
        if (order.wolt_order_id) continue
        if (order.uber_order_id) continue
        history.push(order)
      }
      return history
    }
  }
}
</script>

<style lang="scss">
</style>

